import { createStore } from 'vuex'

export default createStore({
  state: {
    account: null,
  },
  getters: {
  },
  mutations: {
    setEmployeeAccount: function (store, account) {
      store.account = account
    }
  },
  actions: {
    async authenticate(context) {
      const response = await fetch(`${process.env.VUE_APP_API_ORIGIN}/api/accounts/authenticate/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${window.localStorage.getItem('token')}`
        }
      })
      if (response.ok) {
        const account = await response.json()
        context.commit('setEmployeeAccount', account)
        return account
      }
      else {
        context.commit('setEmployeeAccount', null)
        return null
      }
    },
    async login(context, credentials) {
      const response = await fetch(`${process.env.VUE_APP_API_ORIGIN}/api-auth/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      const data = await response.json()
      window.localStorage.setItem('token', data.token)
      await context.dispatch('authenticate')
      return response
    }
  },
  modules: {
  }
})
