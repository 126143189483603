import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'
import {BoardsApi} from "@/api";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:boardSlug/',
    name: 'board',
    component: () => import('../views/BoardView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/projects/',
    name: 'projects',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/gantt/projects/',
    name: 'ganttProjects',
    component: () => import('../views/GanttProjectsView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/gantt/tasks/',
    name: 'ganttTasks',
    component: () => import('../views/GanttTasksView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/projects/reports/',
    name: 'projectReports',
    component: () => import('../views/ProjectReportsView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/employee_reports/',
    name: 'employeeReports',
    component: () => import('../views/EmployeeReportsView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/manager_reports/',
    name: 'managerReports',
    component: () => import('../views/ManagerReportsView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/task_archive/',
    name: 'taskArchive',
    component: () => import('../views/TaskArchiveView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/task_delete/',
    name: 'taskDelete',
    component: () => import('../views/TaskDeletedView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/task_deleted/',
    name: 'taskDeleted',
    component: () => import('../views/TaskDeletedView.vue')
  },
  {
    path: '/:boardSlug/wiki/',
    name: 'wiki',
    component: () => import('../views/WikiView.vue')
  },
  {
    meta: { access: [1, 2] },
    path: '/:boardSlug/vacations/',
    name: 'vacations',
    component: () => import('../views/VacationsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta.access) {

    const boards = await BoardsApi.get()
    const { id: boardId } = boards.find(b => b.slug === to.params.boardSlug)
    const account = await store.dispatch('authenticate')
    const { account_type: account_access } = account.boards_access.find(ba => ba.board === boardId)

    if (!to.meta.access.includes(account_access)) {
      alert("В доступе отказано")
      return { name: 'home' }
    }

  }
})

export default router
