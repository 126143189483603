<template>
  <div class="boards-view">
    <PageHeader/>

    <main class="boards-view__main">
      <div class="board-cards">
        <div class="board-cards__header">
          <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.5 14H16.5C15.9696 14 15.4609 14.2107 15.0858 14.5858C14.7107 14.9609 14.5 15.4696 14.5 16V46C14.5 47.0609 14.9214 48.0783 15.6716 48.8284C16.4217 49.5786 17.4391 50 18.5 50H24.5C25.5609 50 26.5783 49.5786 27.3284 48.8284C28.0786 48.0783 28.5 47.0609 28.5 46V34H36.5V38C36.5 39.0609 36.9214 40.0783 37.6716 40.8284C38.4217 41.5786 39.4391 42 40.5 42H46.5C47.5609 42 48.5783 41.5786 49.3284 40.8284C50.0786 40.0783 50.5 39.0609 50.5 38V16C50.5 15.4696 50.2893 14.9609 49.9142 14.5858C49.5391 14.2107 49.0304 14 48.5 14ZM24.5 46H18.5V30H24.5V46ZM24.5 26H18.5V18H24.5V26ZM36.5 30H28.5V18H36.5V30ZM46.5 38H40.5V30H46.5V38ZM46.5 26H40.5V18H46.5V26Z" fill="#89949C"/>
          </svg>

          <div class="board-cards__title">
            Выбрать доску
          </div>
        </div>

        <div class="board-cards__list">
          <div v-if="boards.length === 0" class="board-cards__placeholder board-cards__item">
          </div>

          <BoardCard
            v-else
            v-for="board in boards"
            :key="board.id"
            :board="board"
            class="board-cards__item"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import BoardCard from '@/components/BoardCard.vue'

import { BoardsApi } from '@/api'

export default {
  name: 'HomeView',

  components: {
    PageHeader,
    BoardCard,
  },

  data() {
    return {
      boards: [],
    }
  },

  mounted() {
    BoardsApi.get().then(boards => {
      let i
      for (i = 0; i < boards.length; ++i) {
        if (this.$store.state.account.boards.includes(boards[i].id)) {
          this.boards.push(boards[i])
        }
      }
    })
  }
}
</script>

<style lang="scss">
.boards-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__main {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
}

.board-cards {
  max-width: 80%;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px;
  }

  &__placeholder {
    width: 320px;
    height: 256px;

    border-radius: 10px;
    background: #2B343B;
  }

  &__item {
    margin: 12px;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    opacity: 0.2;
  }

  &__title {
    margin-left: 16px;

    color: #89949C;

    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}
</style>
