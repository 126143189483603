<template>
  <button class="burger-btn" type="button">
    <span class="burger-btn__inner">
      <span class="burger-btn__item"></span>
      <span class="burger-btn__item"></span>
      <span class="burger-btn__item"></span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'BurgerBtn',
}
</script>

<style lang="scss" scoped>
.burger-btn {
  width: 32px;
  height: 32px;
  padding: 8px 4px;
  border: none;

  background: none;

  &__inner {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    left: 0;
    width: 24px;
    height: 3px;

    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: width 0.3s,
                top 0.3s,
                bottom 0.3s,
                left 0.3s,
                transform 0.3s;

    &:nth-child(1) {
      top: 0;
      width: 16px;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%) scaleX(1);
    }

    &:nth-child(3) {
      bottom: 0;
      width: 16px;
    }
  }
}
</style>
