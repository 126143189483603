<template>
  <header class="page-header">
    <div class="page-header__container">
      <BurgerBtn
        v-if="!isHomePage"
        @click="toggleBurger(!isBurgerActive)"
        class="page-header__burger-btn"
      />

      <teleport to="body">
        <BurgerMenu v-if="!isHomePage" @hide="toggleBurger(false)" :class="{ active: isBurgerActive }"/>
      </teleport>

      <AppLogo>
        vangerdream
      </AppLogo>

      <slot name="content"></slot>

      <AccountMenu class="page-header__account-menu"/>
    </div>
  </header>
</template>

<script>
import BurgerBtn from '@/components/BurgerBtn.vue'
import BurgerMenu from '@/components/BurgerMenu.vue'
import AppLogo from '@/components/AppLogo.vue'
import AccountMenu from '@/components/AccountMenu.vue'

export default {
  name: 'PageHeader',

  components: {
    BurgerBtn,
    BurgerMenu,
    AppLogo,
    AccountMenu,
  },

  computed: {
    isHomePage() {
      return this.$route.name === 'home'
    },
  },

  data() {
    return {
      isBurgerActive: false,
    }
  },

  methods: {
    toggleBurger(active) {
      this.isBurgerActive = active

      // лочим скролл только когда меню на весь экран
      if (window.innerWidth <= 636) {
        document.documentElement.classList.toggle('locked', active)
      }
    },
  },
}
</script>

<style lang="scss">
.page-header {
  background-color: #2B343B;

  &__container {
    display: flex;
    align-items: center;
    padding: 12px 20px;
  }

  &__burger-btn {
    margin-right: 16px;
  }

  &__filter-form {
    display: flex;
    margin: 0 auto;

    @media (max-width: 1300px) {
      margin-right: 0;
    }
  }

  &__account-menu {
    margin-left: auto;
  }

  &__filter-form + &__account-menu {
    margin-left: 0;
  }
}

.filter-form {
  &__input {
    &:not(:last-child) {
      margin-right: 10px;

      @media (max-width: 1300px) {
        margin-right: 0;
      }
    }

    &:not(:first-child) {
      @media (max-width: 1300px) {
        display: none;
      }
    }
  }

  &__project-select {
    width: 100%;
    max-width: 165px;
  }

  &__employee-select {
    width: 100%;
    max-width: 240px;
  }

  &__project-select,
  &__project-select .placeholder-select,
  &__project-select .placeholder-select input{
    height: 40px;
  }

  &__employee-select,
  &__employee-select .placeholder-select,
  &__employee-select .placeholder-select input{
    height: 40px;
  }
}

.header-select .select-user {
  min-height: auto;
  height: 40px;

  .selected {
    min-height: auto;
    height: 40px;
    border: none;

    border-radius: 20px;
    overflow: hidden;
    z-index: 2;
    position: relative;
  }
}
.reports-view {
  .page-header {
    &__account-menu {
      margin-left: 0;
    }
  }
}
</style>
