<template>
  <div class="board-name" :style="`--board-color: ${board.color};`">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 7H8C7.73478 7 7.48043 7.10536 7.29289 7.29289C7.10536 7.48043 7 7.73478 7 8V23C7 23.5304 7.21071 24.0391 7.58579 24.4142C7.96086 24.7893 8.46957 25 9 25H12C12.5304 25 13.0391 24.7893 13.4142 24.4142C13.7893 24.0391 14 23.5304 14 23V17H18V19C18 19.5304 18.2107 20.0391 18.5858 20.4142C18.9609 20.7893 19.4696 21 20 21H23C23.5304 21 24.0391 20.7893 24.4142 20.4142C24.7893 20.0391 25 19.5304 25 19V8C25 7.73478 24.8946 7.48043 24.7071 7.29289C24.5196 7.10536 24.2652 7 24 7ZM12 23H9V15H12V23ZM12 13H9V9H12V13ZM18 15H14V9H18V15ZM23 19H20V15H23V19ZM23 13H20V9H23V13Z" fill="#2B343B"/>
    </svg>

    <div class="board-name__text">
      {{ board.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoardName',

  props: {
    board: {
      type: Object,
      required: true,
      default() {
        return {
          color: '#FFED00',
          name: 'DEFAULT',
        }
      },
    },
  },
}
</script>

<style lang="scss">
.board-name {
  display: flex;
  align-items: center;
  padding: 14px 16px;

  background: var(--board-color);

  &__text {
    margin-top: -2px;
    margin-left: 30px;

    color: #2B343B;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
  }
}
</style>
